@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/FiraSans-Light.woff2') format('woff2'),
    url('/fonts/FiraSans-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/FiraSans-LightItalic.woff2') format('woff2'),
    url('/fonts/FiraSans-LightItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/FiraSans-Bold.woff2') format('woff2'),
    url('/fonts/FiraSans-Bold.woff') format('woff');
}
