.site {
  bottom: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;

  @include webfont;

  @media (min-width: $two-column-width) {
    flex-flow: row-reverse nowrap;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  strong {
    @include webfont-bold;
  }

  em,
  i {
    @include webfont-italic;
  }
}
