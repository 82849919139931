.site-navigation {
  &-pages {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    @media (min-width: $two-column-width) {
      margin-top: 0.25em;
      text-align: right;
    }

    &-item {
      display: inline-block;
      padding: 0.25em 0.5em;

      @media (min-width: $two-column-width) {
        display: block;
        margin-top: 1em;
        padding: 0;
        text-align: right;
      }

      &-link {
        color: $brand-text;
        opacity: 0.3;
        text-decoration: none;
        text-transform: uppercase;
        transition: opacity 0.1s ease-in-out;

        @media (min-width: $two-column-width) {
          border-bottom: 1px solid transparent;
          color: $white;
          opacity: 1;
          transition: border-color 0.1s ease-in-out;
        }

        &:hover {
          color: $brand-text;
          opacity: 1;

          @media (min-width: $two-column-width) {
            border-color: $white;
            color: $white;
          }
        }
      }
    }
  }

  &-social {
    list-style: none;
    margin: 0.5em 0 0;
    padding: 0;
    text-align: center;

    @media (min-width: $two-column-width) {
      margin-top: 1em;
      text-align: right;
    }

    &-item {
      display: inline-block;

      &-link {
        opacity: 0.3;
        text-decoration: none;
        transition: opacity 0.1s ease-in-out;

        &-text {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
