@import '../base/variables';

.site-content {
  flex-grow: 1;

  > * {
    margin: 0 auto;
    max-width: 52em;
  }

  > hr {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
