@mixin webfont {
  & {
    font-family: $sans-serif-font;
  }

  .webfont-stage-1 & {
    font-family: Fira Sans Subset, $sans-serif-font;
    font-weight: 300;
  }

  .webfont-stage-2 & {
    font-family: Fira Sans, $sans-serif-font;
    font-weight: 300;
  }
}

@mixin webfont-bold {
  .webfont-stage-2 & {
    font-weight: 700;
  }
}

@mixin webfont-bold-italic {
  .webfont-stage-2 & {
    font-style: italic;
    font-weight: 700;
  }
}

@mixin webfont-italic {
  .webfont-stage-2 & {
    font-style: italic;
  }
}
