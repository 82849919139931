@use "sass:math";

.site-header {
  margin-top: 5em;
  text-align: center;

  @media (min-width: $two-column-width) {
    background: $brand-primary;
    margin-top: 0;
    text-align: right;
  }

  &-logo {
    display: inline-block;
    fill: $brand-primary;
    height: 80px;
    width: 80px;
  }

  &-title {
    display: inline-block;
    outline: none;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: $two-column-width) {
      text-align: right;
    }

    &-text {
      color: #161614;
      display: inline-block;
      font-size: modular-scale(2);
      line-height: 80px;
      margin: 0 0 0 10px;
      opacity: 0.3;
      padding: 0;
      vertical-align: top;

      @media (min-width: $two-column-width) {
        color: $white;
        display: block;
        line-height: inherit;
        margin-top: 0.25em;
        opacity: 1;
        text-align: right;
        width: 6em;
      }
    }
  }

  @media (min-width: $two-column-width) {
    $site-header-padding: math.div($sidebar-width, 6);
    flex-basis: $sidebar-width;
    padding: $site-header-padding $site-header-padding 0 0;
  }
}
